import React from "react";
import "./contact.css";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom"; // useNavigate'i import ettik

const ContactPage = () => {
  const navigate = useNavigate(); // useNavigate ile yönlendirme işlevini oluşturduk

  return (
    <div className="contact-page">
      <header className="header">
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/denz_industries_left.svg`}
          alt="Logo"
          className="logo"
        />
        <button
          className="homepage-button"
          onClick={() => navigate("/")} // "/" rotasına yönlendirme yapar
        >
          Homepage
        </button>
      </header>

      <section className="contact-section">
        <h2>Contact</h2>
        <div className="contact-cards">
          <div className="contact-card">
            <h3>
              {" "}
              <img
                src="/assets/img/denzWaterLogo.svg"
                alt="Denz Water Logo"
                style={{ width: "80px", marginBottom: "20px" }}
              />
            </h3>
            <p>
              Mutlukent Mahallesi , 2016/1 Sokak <br />
              No: 32 06800
              <br />
              Çankaya, Ankara, Türkiye
            </p>
            <a href="mailto:sales@denz.co">sales@denz.co</a>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/denzwater/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://instagram.com/denzwater"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className="contact-card">
            <h3>
              <img
                src="/assets/img/denzFocusLogo.svg"
                alt="Denz Focus Logo"
                style={{ width: "80px", marginBottom: "20px" }}
              />
            </h3>
            <p>
              İvedik OSB 1354. Cadde 1357. Sokak
              <br />
              No:38 06378
              <br />
              Yenimahalle, Ankara, Türkiye
            </p>
            <a href="mailto:sales@denz.co">sales@denz.co</a>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/focusactuators/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/focusactuators/"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className="contact-card">
            <h3>
              <img
                src="/assets/img/denzOfLogo.png"
                alt="Denz Of Logo"
                style={{ width: "80px", marginBottom: "20px" }}
              />
            </h3>
            <p>
              Konya 2. OSB, Karahisar Sokak, <br />
              No:13/1 42300
              <br />
              Selçuklu, Konya, Türkiye
            </p>
            <a href="mailto:info@denz.co">info@denz.co</a>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/denzofcasting"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/denzofcasting"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="instagram.com/denzwater"
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form-section">
        <h2>
          <span class="text-white">Contact</span>
          <span class="text-blue"> Form</span>
        </h2>
        <form className="contact-form">
          <div className="form-columns">
            <div className="form-column">
              <input type="text" name="name" placeholder="Name" required />
            </div>
            <div className="form-column">
              <input type="text" name="telephone" placeholder="Telephone" />
            </div>
          </div>

          <div className="form-columns">
            <div className="form-column">
              <input type="text" name="company" placeholder="Company" />
            </div>
            <div className="form-column">
              <input type="email" name="email" placeholder="E-mail" required />
            </div>
          </div>

          <div className="form-message">
            <textarea name="message" placeholder="Message" required></textarea>
          </div>

          <div className="form-submit">
            <button type="submit">Send</button>
          </div>
        </form>
      </section>
      <div className="contact-page-footer">
        <Footer />
      </div>
    </div>
  );
};

export default ContactPage;

import React, { useState, useEffect } from "react";
import "./ContainerCard.css";
import CardFocus from "../cardFocus/CardFocus";
import cardData from "../../data/cards-products.json"; // JSON dosyasını içe aktarıyoruz

const ContainerCard = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    // JSON verisini useEffect içinde ayarlıyoruz (örnek bir async yapı oluşturmak için)
    setCards(cardData.cardProducts);
  }, []);

  return (
    <div className="container-card">
      {cards.slice(0, 3).map((card) => (
        <CardFocus key={card.id} data={card} />
      ))}
    </div>
  );
};

export default ContainerCard;

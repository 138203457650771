import React from "react";
import "./App.css";
import Home from "./pages/home/Home.jsx";
import Contact from "./pages/contact/Contact.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <div className="App">
        {/* Video arka plan */}
        <div className="background-video">
          <video autoPlay loop muted playsInline>
            <source src="/assets/videos/backgroundVideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Sayfa içeriği */}
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;

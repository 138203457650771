import React from "react";
import "./footer.css"; // Footer CSS import

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left-content">
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/denz_industries_left.svg`}
            alt="Denz Industries Logo"
            className="footer-logo"
          />
        </div>
        <div className="footer-right-content">
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/allRightsReserved.png`}
            alt="All Rights Reserved"
            className="footer-right-img"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

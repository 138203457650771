import React from "react";
import "./CardFocus.css";

const CardFocus = ({ data, type }) => {
  const cardClass =
    type === "disappear" ? "card-focus-disappear" : "card-focus";
  const firstCardItems = data.hoverView?.firstCardItems;

  return (
    <div className="card-container">
      <div className={cardClass}>
        <div className="card-content">
          {/* İlk kart yüzü */}
          <div className="card-back-view">
            {firstCardItems && (
              <div className="card-back-content">
                {/* Sol kısım: Title ve Back Icon */}
                <div className="card-back-left-section">
                  {firstCardItems.leftTitle && (
                    <h4 className="card-back-title">
                      {firstCardItems.leftTitle}
                    </h4>
                  )}
                  {firstCardItems.backIcon && (
                    <img
                      src={firstCardItems.backIcon}
                      alt="Back Icon"
                      className="card-back-icon"
                      loading="lazy"
                    />
                  )}
                </div>

                {/* Sağ kısım: Dikey sıralı görseller (alt sıradan yukarı) */}
                <div className="card-back-right-section">
                  <div className="card-back-images">
                    {["image4", "image3", "image2", "image1"].map(
                      (key) =>
                        firstCardItems[key] && (
                          <img
                            key={key}
                            src={firstCardItems[key]}
                            alt={key}
                            className="card-back-image"
                            loading="lazy"
                            // Eğer farklı boyutlarda görselleriniz varsa srcset ve sizes ekleyin:
                            // srcSet={`${firstCardItems[key].replace('.jpg', '-small.jpg')} 480w, ${firstCardItems[key]} 1024w`}
                            // sizes="(max-width: 768px) 50vw, 100vw"
                          />
                        )
                    )}
                  </div>
                </div>

                {/* Sol alt kısım: leftimage */}
                {firstCardItems.leftimage && (
                  <div className="card-back-leftimage">
                    <img
                      src={firstCardItems.leftimage}
                      alt="Left Logo"
                      loading="lazy"
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Hover durumunda görünen kart yüzü */}
          <div className="card-hover-view">
            <h4>{data.hoverView.title}</h4>
            <p>{data.hoverView.description}</p>
            <div className="social-links">
              <a
                href={data.hoverView.socialMedia.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <i className="fab fa-linkedin"></i>
              </a>

              <a
                href={data.hoverView.socialMedia.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <div className="logos">
              {data.hoverView.hoverLogo && (
                <img
                  src={data.hoverView.hoverLogo}
                  alt="Hover Logo"
                  className="hover-logo"
                  loading="lazy"
                />
              )}
              {data.defaultView.defaultLogo && (
                <img
                  src={data.defaultView.defaultLogo}
                  alt="Default Logo"
                  className="default-logo"
                  loading="lazy"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Kartın dışında buton */}
      <div className="card-buttons-outside">
        <button
          onClick={() => window.open(data.hoverView.website.url, "_blank")}
          className="card-button-outside"
        >
          {data.hoverView.website.buttonText || "Website"}
        </button>
      </div>
    </div>
  );
};

export default CardFocus;

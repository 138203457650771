import React from "react";
import "./header.css";
import { useNavigate } from "react-router-dom"; // useNavigate'i import ettik

const Header = () => {
  const navigate = useNavigate(); // useNavigate'i başlattık

  return (
    <header className="header">
      <div className="logo-container">
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/denz_industries_left.svg`}
          alt="Logo"
          className="logo"
        />
      </div>
      <button
        className="contact-button"
        onClick={() => navigate("/contact")} // "/contact" rotasına yönlendirme
      >
        Contact
      </button>
    </header>
  );
};

export default Header;

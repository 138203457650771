import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import ContainerCard from "../../components/containerCard/ContainerCard";
import "./home.css";

const Home = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <div className="home">
      <Header buttonText="Contact" buttonRoute="/contact" />
      <div className="content">
        <section className="welcome-section">
          <h1>Welcome to DENZ</h1>
          <p className="welcome-text">
            The gateway to our specialized brands: DENZ Water Technologies,
            Focus Actuators, and DENZOF Casting Industries. We provide
            comprehensive solutions across industrial valves, electric
            actuators, and casting products, backed by innovation and expertise.
            Click on each logo below to learn more about our brands and how they
            can support your business with cutting-edge technology and quality
            products.
          </p>
        </section>
        <ContainerCard />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
